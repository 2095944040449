import React from 'react';

import { HeroBanner, CaseHeader, TextContent, Gallery, GalleryLayout, Team, Testimonial } from 'modules/references';
import { IS_EN_LANG_ENV } from 'config/env';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/prochmel.png';

const CASE_ID = 'prochmel';
const MODULE_NAME = 'prochmel';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    mainGradient: 'linear-gradient(180deg, #D0E200 0%, #AAB800 100%);',
    sideGradient: 'linear-gradient(180deg, rgba(205, 210, 0, 0) 12.02%, #CEE001 63.99%);',
    teamGradient: 'linear-gradient(180deg, #CEE001 66.81%, #A6C100 100%);',
    black: '#000',
    white: '#fff',
};

const team = [
    {
        department: 'references.case.team.management',
        names: ['Maximilian Sterndorff', 'David Bilík'],
    },
    {
        department: 'references.case.team.design',
        names: ['Roman Gordienko'],
    },
    {
        department: 'references.case.team.backend',
        names: ['Pavel Švagr'],
    },
    {
        department: 'references.case.team.web',
        names: ['Jiří Čermák'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Madina Akramova'],
    },
];

interface Props {}
const AsahiProChmel = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero-main${IS_EN_LANG_ENV ? '-en' : ''}.png`,
                    imgStyle: {
                        objectFit: 'contain',
                        objectPosition: 'center',
                    },
                    style: {
                        width: '100%',
                        height: '100%',
                        bottom: '-7.5%',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            background={colors.mainGradient}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero-bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                    objectPosition: 'center',
                },
            }}
            fullHeight={true}
            headerTheme="light"
            textColor={colors.white}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.web',
                    'references.case.output.backend',
                    'references.case.output.testing',
                ],
            }}
            background={colors.white}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.TWO_THIRDS}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/1-1.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center',
                            maxHeight: '920px',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/1-2.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            background={colors.white}
        />

        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
            background={colors.white}
            extendMainContainer={{
                borderRadius: '16px',
                boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.16);',
            }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.TWO_THIRDS}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/2-1${IS_EN_LANG_ENV ? '-en' : ''}.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                            boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.16);',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/2-2${IS_EN_LANG_ENV ? '-en' : ''}.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                            boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.16);',
                        },
                    },
                },
            ]}
            style={{
                height: 'fit-content',
            }}
            background={colors.white}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.white}
            backgroundImage={{
                src: `${IMAGE_PATH}/3-bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                },
            }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4${IS_EN_LANG_ENV ? '-en' : ''}.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
            background={colors.white}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            background={colors.white}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.LITTLE_UNDER_ONE_HALF}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/5-1.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center',
                            maxHeight: '920px',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/5-2.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            background={colors.white}
        />


        {/* @ts-expect-error */}
        <Testimonial
                headerTheme="dark"
                background={colors.sideGradient}
                content={{
                    quote: `case.${CASE_ID}.testimonial.text`,
                    person: {
                        name: `case.${CASE_ID}.testimonial.name`,
                        role: `case.${CASE_ID}.testimonial.position`,
                        image: {
                            src: `${IMAGE_PATH}/img_person_prochmel.jpg`,
                        },
                    },
                }}
                style={{
                    marginTop: '250px'
                }}
                fullHeight={false}
                type="PROFILE_RIGHT"
                backgroundImage={{
                    src: `${IMAGE_PATH}/testi-bg.png`,
                    imgStyle: {
                        right: '0px',
                        objectPosition: 'right',
                        
                    },
                    style: {
                        right: '0px',
                        left: 'unset',
                    }
                }}
                />

        {/* @ts-expect-error */}
        <Team
            headerTheme="dark"
            content={team}
            background={colors.teamGradient}
            textColor={colors.black}
            backgroundImage={{
                src: `${IMAGE_PATH}/team-bg.png`,
                imgStyle: {
                    width: '55vw',
                    height: 'fit-content',
                    maxHeight: '30vh',
                    left: '10%',
                },
            }}
        />
    </>
);

export default AsahiProChmel;
